import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Toast from '@utils/toast.utils'

import Utils from '@utils'
import useStyles from './styles.js'
import Input from '@components/Input'
import Button from '@components/Button'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UserSelect from '../UserSelect'

const CardYear = ({ remove, yearlyValues, formik }) => {
  const { handleBlur, handleChange, values = {}, errors } = formik
  return (
    <>
    {
      yearlyValues.map( (value, index) => {
      return <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel1a-header-${index}`}
        id={`panel1a-header-${index}`}
        >
          <Typography>{value.year}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Input
                name={`yearlyValues[${index}].year`}
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                info="INPUTS_ADD_INPUT_LABEL_YEAR_INFO"
                label="INPUTS_ADD_INPUT_LABEL_YEAR"
                value={Utils.getValue(values, `yearlyValues[${index}].year`)}
                error={Utils.getValue(errors, `yearlyValues[${index}].year`)}
                helperText={Utils.getValue(errors, `yearlyValues[${index}].year`)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name={`yearlyValues[${index}].co2`}
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                label="INPUTS_ADD_INPUT_LABEL_CO2"
                info="INPUTS_ADD_INPUT_LABEL_CO2_INFO"
                value={Utils.getValue(values, `yearlyValues[${index}].co2`)}
                error={Utils.getValue(errors, `yearlyValues[${index}].co2`)}
                helperText={Utils.getValue(errors, `yearlyValues[${index}].co2`)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name={`yearlyValues[${index}].ch4`}
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                label="INPUTS_ADD_INPUT_LABEL_CH4"
                value={Utils.getValue(values, `yearlyValues[${index}].ch4`)}
                error={Utils.getValue(errors, `yearlyValues[${index}].ch4`)}
                helperText={Utils.getValue(errors, `yearlyValues[${index}].ch4`)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name={`yearlyValues[${index}].n2o`}
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                label="INPUTS_ADD_INPUT_LABEL_N2O"
                value={Utils.getValue(values, `yearlyValues[${index}].n2o`)}
                error={Utils.getValue(errors, `yearlyValues[${index}].n2o`)}
                helperText={Utils.getValue(errors, `yearlyValues[${index}].n2o`)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type="number" name={`yearlyValues[${index}].co2Biogenic`}
                onBlur={handleBlur}
                onChange={handleChange}
                label="INPUTS_ADD_INPUT_LABEL_CO2BIOGENIC"
                value={Utils.getValue(values, `yearlyValues[${index}].co2Biogenic`)}
                error={Utils.getValue(errors, `yearlyValues[${index}].co2Biogenic`)}
                helperText={Utils.getValue(errors, `yearlyValues[${index}].co2Biogenic`)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type="number" name={`yearlyValues[${index}].energy`}
                onBlur={handleBlur}
                onChange={handleChange}
                label="INPUTS_ADD_INPUT_LABEL_ENERGY"
                value={Utils.getValue(values, `yearlyValues[${index}].energy`)}
                error={Utils.getValue(errors, `yearlyValues[${index}].energy`)}
                helperText={Utils.getValue(errors, `yearlyValues[${index}].energy`)}
              />
            </Grid>
            <Grid>
              <Button onClick={() => remove(index)} color='secondary'>INPUT_BUTTON_DELETE_YEAR</Button>
            </Grid>
          </Grid>
        </AccordionDetails>
        </Accordion>
    })}
    </>
  )
}


const FormGroup = props => {
  const { formik, phases, subTypes, users } = props
  const classes = useStyles()

  const { handleBlur, handleChange, values = {}, errors, setFieldValue  } = formik
  const [yearlyValues, setYearlyValues] = useState(values.yearlyValues || []);

  const remove = (indexYear) => {
    if(values.yearlyValues.length === 1){
      Toast.sendMessage('error', "É obrigatório ter ao menos um ano cadastrado. ", false)
      return
    }
    const updatedValues = yearlyValues.filter((_, index) => index !== indexYear);
    setYearlyValues(updatedValues);
    setFieldValue('yearlyValues', updatedValues);
  }

  const addYear = () => {
    const newYearlyValue = { co2: '', ch4: '', n2o: '', energy: '', year: 2020 };
    const updatedValues = [...yearlyValues, newYearlyValue];
    setYearlyValues(updatedValues);
    setFieldValue('yearlyValues', updatedValues);
  };

  const onChange = ({ target: { name, value } }) => {
    setFieldValue('isDeleted', value)
  }

  const handleChangeSupplier = ({ target: { name, value } }) => {
    setFieldValue('supplier', value)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item container spacing={3}>
          <Grid item xs>
            <Input
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              label="INPUTS_ADD_INPUT_LABEL_SUB_DESCRIPTION"
              value={Utils.getValue(values, 'description')}
              error={Utils.getValue(errors, 'description')}
              helperText={Utils.getValue(errors, 'description')}
            />
          </Grid>
          {values?._id &&
            <Grid item xs={6} sm={6}>
              <Input
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                label="INPUTS_ADD_INPUT_LABEL_CATEGORY"
                value={Utils.getValue(values, 'category')}
                error={Utils.getValue(errors, 'category')}
                helperText={Utils.getValue(errors, 'category')}
              />
            </Grid>
          }
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            name="unit"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_UNIT"
            value={Utils.getValue(values, 'unit')}
            error={Utils.getValue(errors, 'unit')}
            helperText={Utils.getValue(errors, 'unit')}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            name="phases"
            array={phases}
            onBlur={handleBlur}
            type="multipleSelect"
            nameValue="description"
            onChange={handleChange}
            value={Utils.getValue(values, 'phases')}
            error={Utils.getValue(errors, 'phases')}
            label="INPUTS_ADD_INPUT_LABEL_PHASES"
            helperText={Utils.getValue(errors, 'phases')}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            type="select"
            name="subtype"
            array={subTypes}
            onBlur={handleBlur}
            nameValue="description"
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_SUBTYPE"
            value={Utils.getValue(values, 'subtype')}
            error={Utils.getValue(errors, 'subtype')}
            helperText={Utils.getValue(errors, 'subtype')}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Input
            type="checkbox"
            name={'isDeleted'}
            label={"INPUTS_ADD_INPUT_LABEL_VISIBLED"}
            onBlur={handleBlur}
            onChange={onChange}
            value={Utils.getValue(values, 'isDeleted')}
            error={Utils.getValue(errors, 'isDeleted')}
            helperText={Utils.getValue(errors, 'isDeleted')}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Input
            type="checkbox"
            name={'supplier'}
            label={"INPUTS_ADD_INPUT_LABEL_SUPPLIER"}
            onBlur={handleBlur}
            onChange={handleChangeSupplier}
            value={Utils.getValue(values, 'supplier') || ((Utils.getValue(values, 'users') && !!values.users.length))}
          />
        </Grid>
        {
          (Utils.getValue(values, 'supplier') || 
          (Utils.getValue(values, 'users') && !!values.users.length)) && 
          <Grid item xs={6} sm={6}>
            <UserSelect 
              value={Utils.getValue(values, 'users')}
              options={users}
              setFieldValue={setFieldValue} 
              usersEdit={Utils.getValue(values, 'users') || []} 
              touched 
              errors 
            />
          </Grid>
        }
        <Grid item xs={6} sm={6}>
          <Button onClick={addYear}>INPUT_BUTTON_ADD_YEAR</Button>
        </Grid>

        <CardYear remove={remove} yearlyValues={values.yearlyValues} formik={formik}/>

      </Grid>
    </div>
  )
}

export default FormGroup
