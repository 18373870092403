import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress, TextField } from "@material-ui/core";
import { http } from '@components/Axios'

const UserSelect = ({ setFieldValue, usersEdit, touched, errors, value, options}) => {
  const [users, setUsers] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState( usersEdit || []);
  

  useEffect(() => {
    fetchUsers(true); 
  }, [searchParam]);

  useEffect(() => {
    setTimeout(() => {
      if (usersEdit?.length && options.length) {
        setSelectedUsers(usersEdit.map(user => options.find(p => user == p.id) || null));
      }
    }, 1000)

  }, [usersEdit]);

  const fetchUsers = async (newSearch = false) => {
    if (isLoading) return;
    setIsLoading(true)
    let data = await http({
        api: 'user.search',
        others: { page, searchParam },
        method: 'get',
      })

   setUsers((prev) =>
      newSearch ? data.data.users : [...prev, ...data.data.users, ...selectedUsers]
    );
    setIsLoading(false);
  };

  const handleSearchChange = (event) => {
    setSearchParam(event.target.value);
    setPage(1);
   setUsers([]);
  };

  const handleSelectionChange = (event, value) => {
    setSelectedUsers(value);
    setFieldValue(
      "users",
      value.map((user) => user.id)
    );
  };

  
  return ( 
    <Autocomplete
      multiple 
      options={users.sort((a , b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })}
      error={touched.users && Boolean(errors.users)}
      getOptionLabel={(option) => {
        return option.name
      }}
      value={selectedUsers}
      onChange={handleSelectionChange}
      onInputChange={(event, value) => handleSearchChange({ target: { value } })}
      filterOptions={(x) => x}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Usuários"
          variant="outlined"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={10} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props) => (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#000' }}>
              <span>{props.name}</span>
            </div>
          </div>
      )}
      fullWidth
    />
   );
}
 
export default UserSelect;